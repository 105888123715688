import {useContext, useEffect, useState} from "react";
import {Redirect, useParams} from "react-router";
import {AuthenticationService} from "@services/AuthenticationService";
import AuthHeader from "@ui-components/layout/AuthHeader";
import PoweredBy from "@ui-components/layout/PoweredBy";
import {useIntl} from "react-intl";
import {LoginForm} from "feature/login";
import {useMFA, MFASetup, MFAVerification} from "feature/mfa";
import {LOGIN_INITIAL_STATE} from "./lib/consts";
import jwtDecode from "jwt-decode";
import {NotificationsContext} from "@ui-components/Notifications";
import Spinner from "@ui-components/Spinner";

function Login() {

  // context
  const {push} = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const {token} = useParams();
  const {requestSetupUrl, verify} = useMFA();

  // state
  const [state, setState] = useState(LOGIN_INITIAL_STATE);

  useEffect(() => {
    // Pre-initialize the state with token information (if present). This is useful when the user is signing up and is
    // redirected to the login page with the token in the URL, in order to start the MFA setup process. This is also
    // used to complete the OAuth2 flow (i.e. MFA verification step only) if it requires in-app MFA.
    if (!token)
      return;
    const decoded = jwtDecode(token);
    const mfaConfigurationCompleted = decoded.mfa_status !== 'NOT_CONFIGURED';
    const edits = {accessToken: decoded, rawAccessToken: token, mfaConfigurationCompleted};
    if (!mfaConfigurationCompleted)
      requestSetupUrl(token).then((url) => {
        setState((prev) => ({
          ...prev,
          ...edits,
          mfaConfigurationURL: url,
        }));
      });
    else
      setState((prev) => ({...prev, ...edits}));
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // handlers
  const onLoginSuccess = async (rawToken) => {
    const decoded = jwtDecode(rawToken);
    const newState = {
      accessToken: decoded,
      rawAccessToken: rawToken,
    };
    if (decoded.mfa_status === 'NOT_CONFIGURED') {
      newState.mfaConfigurationURL = await requestSetupUrl(rawToken);
      newState.mfaConfigurationCompleted = false;
    }
    else
      newState.mfaConfigurationCompleted = true;
    setState(newState);
  }

  if (AuthenticationService.isUserAuthenticated())
    return <Redirect to="/home" />;

  let content;
  if (state.accessToken?.mfa_status === "NOT_CONFIGURED" && !state.mfaConfigurationURL)
    // User is signing up --> Wait for the MFA setup URL
    content = (
      <div className="relative w-10 h-10">
        <Spinner styleType="white" />
      </div>
    )
  else if (!state.accessToken)
    // User is not logged in --> Render login form
    content = (
      <LoginForm
        unauthorizedUser={window.location.href.includes("unauthorized_user")}
        onSuccess={onLoginSuccess}
      />
    )
  else if (!state.mfaConfigurationCompleted)
    // User has MFA enabled but not configured --> Render MFA setup
    content = (
      <MFASetup
        url={state.mfaConfigurationURL}
        onContinue={() => {
          setState((prev) => ({...prev, mfaConfigurationCompleted: true}))
        }}
        onExit={() => setState(LOGIN_INITIAL_STATE)}
      />
    )
  else {
    // User has MFA enabled and configured --> Render MFA verification step
    const secret = state.mfaConfigurationURL
      ? new URL(state.mfaConfigurationURL).searchParams.get("secret")
      : undefined;
    content = (
      <MFAVerification
        email={state.accessToken.sub}
        onContinue={(totp) => {
          verify(totp, state.rawAccessToken, secret)
            .then(({access_token, refresh_token, user_data}) => {
              AuthenticationService.setAuthToken(access_token, refresh_token, user_data);
              window.location = "/home";
            })
            .catch((error) => {
              const code = error.response?.status;
              if (code === 406)
                push({
                  type: "error",
                  title: intl.formatMessage({
                    id: "invalid_mfa_code",
                    defaultMessage: "Il codice inserito non è valido. Riprovare",
                  })
                });
              else
                push({type: "error", title: intl.formatMessage({id: "server_error"})});
            });
        }}
        onExit={() => {
          const edits = !!state.mfaConfigurationURL
            ? {mfaConfigurationCompleted: false}
            : LOGIN_INITIAL_STATE;
          setState((prev) => ({...prev, ...edits}))
        }}
        showTitle={!!secret}
      />
    )
  }

  return (
    <div className="bg-am-400 min-h-full flex flex-col gap-8 items-center justify-center py-6 px-6 lg:px-8">
      <AuthHeader title={intl.formatMessage({id: "log_into_your_account"})} />
      <div className="flex-shrink bg-white py-8 shadow rounded-3xl px-9 min-w-[24rem]">
        {content}
      </div>
      <PoweredBy />
    </div>
  )
}

export default Login;
