import React from "react";

const Checkbox = ({ id, name, checked, classNames = "", onChange, disabled = false, ...rest }) => {
  const hasReadOnly = rest.hasOwnProperty("readOnly");
  if (hasReadOnly) {
    console.warn("🚨 Checkbox component does not support readOnly prop. Use disabled instead and remove readOnly (everywhere!) 🚨");
  }

  return <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      onChange={onChange || (() => null)}
      className={`focus:ring-am-500 h-4 w-4 text-am-600 border-gray-300 rounded ${classNames}`}
      disabled={disabled}
      {...rest}
    />
}

export default Checkbox;