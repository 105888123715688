import {MFAVerificationProps} from "./types";
import StepsProgressBar from "@ui-components/StepsProgressBar";
import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import Button from "@ui-components/Button";
import {TOTPInput} from "feature/mfa";
import Modal from "@ui-components/Modal";

export const MFAVerification = ({
  email,
  showTitle = false,
  onContinue,
  onExit,
}: MFAVerificationProps) => {

  // state
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [openHelpModal, setOpenHelpModal] = useState(false);

  return <>
    <div className="flex flex-col gap-8">
      {showTitle && (
        <div className="flex flex-col gap-2">
          <StepsProgressBar currentIdx={1} count={2}/>
          <h2 className="text-2xl font-bold mt-2">
            <FormattedMessage id="mfa_verification" defaultMessage="Verifica MFA"/>
          </h2>
        </div>
      )}
      <div className="text-sm">
        Account: <span className="font-medium">{email}</span>
      </div>
      <div className="w-full flex flex-col items-center justify-center gap-4 p-4">
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-semibold">
            <FormattedMessage
              id="mfa_insert_otp_title"
              defaultMessage="Inserisci il codice a 6 cifre"
            />
          </h3>
          <h4>
            <FormattedMessage
              id="mfa_insert_otp_subtitle"
              defaultMessage="che compare sulla tua app di autenticazione"
            />
          </h4>
        </div>
        <TOTPInput {...{otp, setOtp}} />
      </div>
      <span
        className="text-am-700 underline hover:no-underline cursor-pointer text-sm"
        onClick={() => setOpenHelpModal(true)}
      >
        <FormattedMessage id="mfa_guide_guide3_title" />
      </span>
      <div className="flex flex-row justify-between gap-2">
        <Button
          styleType="white"
          full
          onClick={onExit}
        >
          <FormattedMessage id="back" defaultMessage="Indietro" />
        </Button>
        <Button
          full
          disabled={otp.some(digit => digit === '')}
          onClick={() => onContinue(otp.join(''))}
        >
          <FormattedMessage id="next" defaultMessage="Avanti" />
        </Button>
      </div>
    </div>
    {openHelpModal && (
      // @ts-ignore
      <Modal
        opened
        onExit={() => setOpenHelpModal(false)}
      >
        <div className="flex flex-col gap-4">
          <h3 className="text-lg font-semibold">
            <FormattedMessage id="mfa_guide_guide3_title"/>
          </h3>
          <p className="text-sm">
            <FormattedMessage
              id="mfa_guide_guide3_text"
              values={{
                address: <a href="mailto:supporto@ammagamma.com">supporto@ammagamma.com</a>,
              }}
            />
          </p>
        </div>
      </Modal>
    )}
  </>
}