import {FormattedMessage, useIntl} from "react-intl";
import Input from "@ui-components/Input";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";
import {PasswordRecoveryModalProps} from "./types";
import React, {useContext, useState} from "react";
import {usePasswordRecovery} from "../hooks/usePasswordRecovery";
import {NotificationsContext} from "@ui-components/Notifications";

export const PasswordRecoveryModal = ({onExit}: PasswordRecoveryModalProps) => {

  // context
  const {push} = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const {submit, loading} = usePasswordRecovery();

  // state
  const [email, setEmail] = useState("");

  // utilities
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submit(email)
      .then(() => {
        push({type: "success", title: intl.formatMessage({id: "forgot_password_msg"})});
        onExit();
      })
      .catch(() => push({type: "error", title: intl.formatMessage({id: "server_error"})}));
  }

  return (
    // @ts-ignore
    <Modal opened onExit={onExit} >
      <div className="w-full flex-col flex gap-2 items-center my-6">
        <h3 className="text-center text-2xl font-extrabold text-gray-900">
          <FormattedMessage id="restore_password"/>
        </h3>
        <p className="text-center text-sm text-gray-600 mb-4 font-medium">
          <FormattedMessage id="restore_password"/>
        </p>
        <form
          action="#"
          method="POST"
          className="w-full max-w-sm mt-2"
          onSubmit={onSubmit}
        >
          {/* @ts-ignore */}
          <Input
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            label="Email"
            type="email"
            required
          />
          <Button full type="submit" submitting={loading} >
            <FormattedMessage id="submit_password"/>
          </Button>
        </form>
      </div>
    </Modal>
  )
}