import "./App.css";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./ui-components/PrivateRoute";
import Dashboard from "./ui-components/Dashboard";
import Login from "./ui-components/pages/Login";
import ExternalAuth from "./ui-components/pages/OAuth2";
import Registration from "./ui-components/pages/Registration";
import Notifications from "./ui-components/Notifications";
import menu from "./ui-components/AppNavigation/menu";
import Logout from "./ui-components/pages/Logout";
import NotFound from "./ui-components/pages/NotFound";
import ErrorBoundary from "./ui-components/ErrorBoundary";
import locale from "./locale";
import publicRoutes from "./routes/public";
import usePageViews from "@hooks/usePageViews";
import { useEffect } from "react";
import {DataProvider} from "@services/DataContext/DataContext";
import { NavigationProvider } from "@services/NavigationContext";
import { ApiAuthProvider } from "@services/ApiRequestContext";
import { HeaderProvider } from "@services/HeaderContext";

function App() {
  usePageViews();

  const currentLocale = localStorage.getItem("lang") || "it";

  // set the current locale in the <html> tag
  useEffect(() => {
    document.documentElement.lang = currentLocale;
  }, [currentLocale]);

  return (
    <NavigationProvider>
      <HeaderProvider>
        <ErrorBoundary>
            <DataProvider>
              <IntlProvider
                messages={locale[localStorage.getItem("lang")]}
                locale={localStorage.getItem("lang")}
                defaultLocale="it"
              >
                <Notifications>
                  <ApiAuthProvider>
                    <Switch>
                      <PrivateRoute
                        exact
                        path="/"
                        component={() => <Redirect to={{ pathname: "/home" }} />}
                      />
                      <Route path="/login/:token" component={Login} />
                      <Route path="/login" component={Login} />
                      <Route path="/logout" component={Logout} />
                      <Route path="/external-auth" component={ExternalAuth} />
                      <Route
                        exact
                        path="/registration/:token"
                        component={Registration}
                      />

                      {publicRoutes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.href}
                          component={route.component}
                        />
                      ))}

                    <PrivateRoute
                      path={[
                        ...menu
                          .reduce(
                            (acc, route) => [
                              ...acc,
                              ...(route.dropdown ? route.dropdown : [route]),
                            ],
                            []
                          )
                          .map((route) => route.href),
                        "/profile",
                      ]}
                      component={Dashboard}
                    />
                    <Route path="*" component={NotFound}/>
                  </Switch>
                </ApiAuthProvider>
              </Notifications>
            </IntlProvider>
          </DataProvider>
        </ErrorBoundary>
      </HeaderProvider>
    </NavigationProvider>
  );
}

export default App;
