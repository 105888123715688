import React from "react";
import Label from "./Label";

const RadioGroup = ({
  id = "r-groups",
  title,
  description,
  options,
  onChange,
  currentValue,
  horizontal,
  labelClasses = "",
  disabled = false,
}) => {
  if (!options || !options.length) {
    throw new Error("missing options");
  }

  return (
    <div className="mb-1">
      {title ? (
        <Label
          className={`text-base font-medium text-gray-900 ${labelClasses}`}
        >
          {title}
        </Label>
      ) : null}
      {description ? (
        <p className="text-sm leading-5 text-gray-500">{description}</p>
      ) : null}
      <fieldset className="mt-4" id={id}>
        <div
          className={
            horizontal
              ? "mt-1 flex flex-wrap -mx-4"
              : "mt-1 flex flex-col space-y-4"
          }
        >
          {options.map(({ name, value }) => (
            <div
              onClick={() => {
                onChange(value);
              }}
              key={value}
              className={
                horizontal
                  ? "relative flex items-center mb-2 ml-4 cursor-pointer"
                  : "relative flex items-center cursor-pointer"
              }
            >
              <input
                name={id}
                id={value}
                type="radio"
                defaultChecked={currentValue === value}
                className="focus:ring-am-500 h-4 w-4 cursor-pointer text-am-600 border-gray-300"
                disabled={disabled}
              />
              <label
                htmlFor={value}
                className="ml-3 block text-sm font-medium cursor-pointer text-gray-700"
              >
                {name}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default RadioGroup;
