import React from "react";
import LogoAmmagamma from "./LogoAmmagamma";

const PoweredBy = () => {
  return (
    <div className="flex items-center justify-center">
      <p className="mr-3">Powered by</p>
      <a
        href="https://www.ammagamma.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LogoAmmagamma />
      </a>
    </div>
  );
};

export default PoweredBy;
