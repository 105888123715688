import React, {useState, useEffect, useCallback, useContext} from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import AuthHeader from "../../layout/AuthHeader";
import PoweredBy from "../../layout/PoweredBy";
import AuthenticationService from "../../../services/AuthenticationService";
import Alert from "../../Alert";
import Button from "../../Button";
import Container from "../../Container";
import Input from "../../Input";
import UpdatePassword from "../../forms/UpdatePassword";
import { api } from "@services/apiRequest";
import jwtDecode from "jwt-decode";
import {NotificationsContext} from "@ui-components/Notifications";
import {useHistory} from "react-router-dom";

const Registration = () => {
  const history = useHistory();
  const intl = useIntl();
  const { push } = useContext(NotificationsContext);
  const { token } = useParams();
  const [uid, setUid] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [passwordless, setPasswordless] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .post("/users/validate", { token: token }, { publicRequest: true })
      .then(({ data: {user, passwordless} }) => {
        // Set user data
        setUid(user.id);
        setEmail(user.email);
        // Set passwordless status
        setPasswordless(passwordless);
        if (passwordless)
          setIsValid(true);
      })
      .catch((err) => {
        console.error("Error:", err);
        setError("Not authorized");
        setIsValid(false);
      })
      .finally(() => setLoading(false));
  }, [token]);

  const submit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!isValid) {
          return;
        }

        setLoading(true);
        setError("");

        // 1. Validate signup
        const { data: response } = await api.post(
          "/users/signup",
          { uid: uid, password: passwordless ? null : password },
          { publicRequest: true }
        );
        if (response.errors) {
          if(response.errors[0] === "ERR_OLD_PASSWORD")
            setError(intl.formatMessage({ id: "pwd_validator_err_old_password" }));
          else
            setError(intl.formatMessage({ id: "form_error" }));
          return;
        }

        if (passwordless) {
          // 2.A. If passwordless, redirect user to login page
          push({
            title: intl.formatMessage({
              id: "passwordless_registration_successful",
              defaultMessage: "Registrazione effettuata con successo"
            }),
            text: intl.formatMessage({
              id: "passwordless_registration_successful_message",
              defaultMessage: "Ora puoi accedere utilizzando il SSO della tua organizzazione"
            }),
            type: "success",
          });
          history.push("/login");
        }
        else {
          // 2.B. If password is set, login user with their credentials
          try {
            const token = await AuthenticationService.login(email, password);
            const {mfa_status} = jwtDecode(token);
            if (mfa_status !== "SUCCESS")
              window.location = `/login/${token}`
          } catch (error) {
            setError(intl.formatMessage({ id: "authentication_failed" }));
          }
        }

      } catch (error) {
        setError(intl.formatMessage({ id: "error_while_saving" }));
      } finally {
        setLoading(false);
      }
    },
    [password, uid, email, intl, isValid] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className="bg-am-400 min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Container>
        <AuthHeader title="Registrazione utente" />

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-3xl sm:px-10 mb-8">
            <form action="#" method="POST" onSubmit={submit}>
              <div>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  label="Email"
                  type="email"
                  disabled
                />
              </div>

              {!passwordless && (
                <UpdatePassword
                  password={password}
                  setPassword={setPassword}
                  confirmPassword={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                  onValid={setIsValid}
                />
              )}
              {passwordless && (
                <div className="mb-4">
                  <Alert
                    type="info"
                    title={intl.formatMessage({
                      id: "passwordless_signup_title",
                      defaultMessage: "Non è necessario impostare una password?"
                    })}
                    text={intl.formatMessage({
                      id: "passwordless_signup_text",
                      defaultMessage: "Potrai accedere utilizzando il Single Sign On (SSO) della tua organizzazione",
                    })}
                  />
                </div>
              )}

              <div>
                <Button
                  full
                  type="submit"
                  submitting={loading}
                  disabled={!isValid}
                >
                  <FormattedMessage id="signup" />
                </Button>
              </div>
              {error ? (
                <div className="mt-4">
                  <Alert slim type="error" title={error} />
                </div>
              ) : null}
            </form>
          </div>
          <PoweredBy />
        </div>
      </Container>
    </div>
  );
};

export default Registration;
