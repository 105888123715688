import {MFASetupProps} from "./types";
import {FormattedMessage} from "react-intl";
import QRCode from "react-qr-code";
import Button from "@ui-components/Button";
import React, {useState} from "react";
import Checkbox from "@ui-components/Checkbox";
import StepsProgressBar from "@ui-components/StepsProgressBar";
import Modal from "@ui-components/Modal";

export const MFASetup = ({
  url,
  onContinue,
  onExit,
}: MFASetupProps) => {

  // state
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal]
    = useState<"guide1" | "guide2" | null>(null);

  return <>
    <div className="flex flex-col gap-3">
      <StepsProgressBar currentIdx={0} count={2} />
      <h2 className="text-2xl font-bold mt-2">
        <FormattedMessage id="mfa_setup" defaultMessage="Impostazione MFA"/>
      </h2>
      <ul className="list-disc list-inside text-sm">
        {(["guide1", "guide2"] as ("guide1" | "guide2")[]).map((guide, idx) => (
          <li
            key={idx}
            className="text-am-700 underline hover:no-underline cursor-pointer"
            onClick={() => setOpenModal(guide)}
          >
            <FormattedMessage id={`mfa_guide_${guide}_title`} />
          </li>
        ))}
      </ul>
      <div className="flex justify-center my-5">
        <QRCode value={url} size={160} />
      </div>
      <div className="max-w-prose flex flex-col gap-1">
        <p className="text-sm">
          <FormattedMessage
            id="mfa_setup_message"
            defaultMessage="Inquadra questo codice QR con una applicazione di autenticazione a due fattori installata sul tuo telefono (come Google Authenticator o Microsoft Authenticator)."
          />
        </p>
        <p className="text-xs">
          <FormattedMessage
            id="mfa_setup_message_mobile"
            defaultMessage="Oppure {link} se stai utilizzando un dispositivo mobile"
            values={{
              link: <a href={url} target="_blank" rel="noreferrer" className="lowercase text-am-700 underline hover:no-underline">
                <FormattedMessage id="click_here" defaultMessage="Clicca qui"/>
              </a>,
            }}
          />
        </p>
      </div>
      <div className="flex flex-row items-center justify-center gap-1.5 text-sm my-4">
        <Checkbox
          id="mfa-setup-checkbox"
          name="mfa-setup-checkbox"
          checked={checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.target.checked)}
          classNames={undefined}
        />
        <FormattedMessage
          id="mfa_setup_checkbox_message"
          defaultMessage="Ho impostato il nuovo account sulla mia app"
        />
      </div>
      <div className="flex flex-row justify-between gap-2">
        <Button
          styleType="white"
          full
          onClick={onExit}
        >
          <FormattedMessage id="exit" defaultMessage="Esci" />
        </Button>
        <Button
          full
          disabled={!checked}
          onClick={onContinue}
        >
          <FormattedMessage id="next" defaultMessage="Avanti" />
        </Button>
      </div>
    </div>
    {openModal === "guide1" && (
      // @ts-ignore
      <Modal
        opened
        onExit={() => setOpenModal(null)}
      >
        <div className="flex flex-col gap-4">
          <h3 className="text-lg font-semibold">
            <FormattedMessage id="mfa_guide_guide1_title"/>
          </h3>
          <p className="text-sm">
            <FormattedMessage id="mfa_guide_guide1_text"/>
          </p>
        </div>
      </Modal>
    )}
    {openModal === "guide2" && (
      // @ts-ignore
      <Modal
        opened
        type="info"
        onExit={() => setOpenModal(null)}
      >
        <div className="flex flex-col gap-4">
          <h3 className="text-lg font-semibold">
            <FormattedMessage id="mfa_guide_guide2_title"/>
          </h3>
          <div className="w-full text-left flex flex-col gap-2">
            <p className="text-sm"><FormattedMessage id="mfa_guide_guide2_text"/></p>
            <ol className="list-inside list-decimal text-xs flex flex-col gap-1">
              <li><FormattedMessage id="mfa_guide_guide2_text_item1"/></li>
              <li><FormattedMessage id="mfa_guide_guide2_text_item2"/></li>
              <li><FormattedMessage id="mfa_guide_guide2_text_item3"/></li>
              <li><FormattedMessage id="mfa_guide_guide2_text_item4"/></li>
            </ol>
          </div>
        </div>
      </Modal>
    )}
  </>
}