const StepsProgressBar = ({ count, currentIdx }) => (
  <div className="w-full flex flex-row gap-2 justify-between">
    {Array.from({ length: count }, (_, idx) => (
      <div
        key={idx}
        className={
          `flex-1 h-1 rounded-full 
          ${idx <= currentIdx ? 'bg-am-700' : 'bg-gray-200'} 
          ${idx === currentIdx ? 'animate-pulse' : ''}`
        }
      />
    ))}
  </div>
)

export default StepsProgressBar;