import Input from "@ui-components/Input";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "@ui-components/Button";
import Alert from "@ui-components/Alert";
import React, {useState} from "react";
import {LoginFormProps} from "./types";
import Checkbox from "@ui-components/Checkbox";
import {useLogin} from "../hooks/useLogin";
import {PasswordRecoveryModal} from "../password-recovery-modal";
import Placeholder from "@ui-components/Placeholder";
import { MAX_FAILED_LOGIN_ATTEMPTS } from "@utils/constants";


export const LoginForm = ({
  unauthorizedUser,
  onSuccess,
}: LoginFormProps) => {

  // hooks
  const intl = useIntl();
  const {
    submit,
    submitting,
    methods,
    redirect,
  } = useLogin();

  // state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal]
    = useState(false);
  const [error, setError] = useState<"unauthorized" | "generic" | "user_locked" | "wrong_email_or_password" | "password_expired" | null>(unauthorizedUser ? "unauthorized" : null);
  const [numRemainingAttempts, setNumRemainingAttempts] = useState(MAX_FAILED_LOGIN_ATTEMPTS);

  const getLocalizedString = (id: string, values = {}) => {
      return intl.formatMessage({id}, values);
    };
  

  // handlers
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    submit(username, password)
      .then(onSuccess)
      .catch((error) => {
        const code = error?.response?.status;
        const errorMessage = error?.response?.data?.detail;
        if (code === 401 && (errorMessage !== "user_locked" && errorMessage !== "password_expired" && errorMessage !== "incorrect_username_password"))
          setError("unauthorized");
        else if (numRemainingAttempts > 0 && errorMessage === "incorrect_username_password") {
          setNumRemainingAttempts(numRemainingAttempts - 1);
          setError("wrong_email_or_password");
        }
        else if (numRemainingAttempts === 0)
          setError("user_locked");
        else if (errorMessage === "password_expired")
          setError("password_expired");
        else  
          setError("generic");
        setPassword("");
      })
  }

  // utilities
  const anyMethod = [methods?.accenture_eso, methods?.client_sso, methods?.standard_login].some(Boolean);
  const hasSSO = [methods?.accenture_eso, methods?.client_sso].some(Boolean);
  const getErrorMessage = () => {
    switch (error) {
      case "unauthorized":
        return intl.formatMessage({id: "wrong_email_or_password"});
      case "generic":
        return intl.formatMessage({id: "generic_error"});
      case "user_locked":
        return intl.formatMessage({id: "user_locked"});
      case "wrong_email_or_password":
        return getLocalizedString("wrong_email_or_password", {number: numRemainingAttempts})
      case "password_expired":
        return intl.formatMessage({id: "password_expired"});
      default:
        return null;
    }
  }

  // render
  // -- case 1: loading authentication methods --> show placeholders
  if (!methods)
    return (
      <div className="w-full">
        {Array.from({length: 3}, (_, i) => <Placeholder key={i} height="h-8" />)}
      </div>
    )
  // -- case 2: no authentication methods available --> show error message
  if (!anyMethod)
    return (
      // @ts-ignore
      <Alert
        type="error"
        title={intl.formatMessage({
          id: "no_login_methods_title",
          defaultMessage: "Nessun metodo di accesso disponibile"
        })}
      />
    )
  // -- case 3: show login form
  return (
    <div className="flex flex-col gap-2">
      {methods.accenture_eso && (
        <Button full onClick={() => redirect("accenture")}>
          Accenture SSO
        </Button>
      )}
      {methods.client_sso && (
        <Button full onClick={() => redirect("client")}>
          {methods?.client_sso_name || "Client"} SSO
        </Button>
      )}
      {methods.standard_login && <>
        {[methods.accenture_eso, methods.client_sso].some(Boolean) && (
          <div className="flex flex-row items-center gap-2 my-2">
            <div className="flex-1 border-t border-gray-300"/>
            <span className="lowercase text-xs text-gray-400">
            <FormattedMessage id="or_long" defaultMessage="oppure"/>
          </span>
            <div className="flex-1 border-t border-gray-300"/>
          </div>
        )}
        <form action="#" method="POST" onSubmit={onSubmit}>
          {/* @ts-ignore */}
          <Input
            value={username}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setUsername(e.target.value);
            }}
            label="Email"
            type="email"
            required
            errorMessage={intl.formatMessage({id: "enter_email"})}
            data-cy="login-email"
          />
          {/* @ts-ignore */}
          <Input
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value);
            }}
            label="Password"
            type={showPassword ? "text" : "password"}
            required
            data-cy="login-password"
            autocomplete="off"
          />
          <div className="flex items-center justify-between gap-20 mb-6 text-sm">
            <div className="flex flex-row gap-2 items-center">
              <Checkbox
                id="show-password"
                name="show-password"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                classNames={undefined}
              />
              <label
                htmlFor="remember-me"
                className="text-gray-900"
              >
                <FormattedMessage id="show_password"/>
              </label>
            </div>
            <p
              onClick={() => setShowForgotPasswordModal(true)}
              className="font-medium text-am-600 hover:text-am-500 cursor-pointer"
            >
              <FormattedMessage id="forgot_password" />
            </p>
          </div>
          <div>
            <Button
              full
              type="submit"
              styleType={hasSSO ? "white" : "primary"}
              submitting={submitting}
              data-cy="login-submit"
            >
              <FormattedMessage
                id="login"
                defaultMessage="Accedi"
              />
            </Button>
          </div>
        </form>
      </>}
      {!!error && (
        <div className="mt-4">
          {/* @ts-ignore */}
          <Alert slim type="error" title={getErrorMessage()} />
        </div>
      )}
      {showForgotPasswordModal && (
        <PasswordRecoveryModal onExit={() => setShowForgotPasswordModal(false)} />
      )}
    </div>
  )
}