import React, { useEffect, useState } from "react";
import AuthenticationService from "../../../services/AuthenticationService";
import { Redirect } from "react-router-dom";


export default function ExternalAuth(method) {

  const [authenticated, setAuthentication] = useState(false);
  const [authorized, setAuthorization] = useState(true);
  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    const token = query.get("access_token");
    const refresh_token = query.get("refresh_token");
    const userData = query.get("user_data");

    if (token && userData) {
      AuthenticationService.setAuthToken(
        token,
        refresh_token,
        JSON.parse(userData)
      );
    } else {
      setAuthorization(false);
    }

    if (AuthenticationService.isUserAuthenticated()) {
      console.log(`User authenticated with ${method}. Back to home`);
      setAuthentication(true);
    }
  }, [authenticated, authorized, query]); // eslint-disable-line react-hooks/exhaustive-deps

  if (authenticated) {
    window.location = "/home";
    return <div />;
  }

  if (!authorized) {
    return <Redirect to={"/login?unauthorized_user"} />;
  }

  return <div />;
}
