import {MFAWizardModalProps} from "./types";
import Modal from "@ui-components/Modal";
import React, {useContext, useEffect, useState} from "react";
import AuthenticationService from "@services/AuthenticationService";
import {MFASetup, MFAVerification, useMFA} from "feature/mfa";
import Spinner from "@ui-components/Spinner";
import jwtDecode from "jwt-decode";
import {NotificationsContext} from "@ui-components/Notifications";
import {useIntl} from "react-intl";

export const MFAWizardModal = ({
  onExit,
  onSuccess,
}: MFAWizardModalProps) => {

  // context
  const {push} = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const {requestSetupUrl, verify} = useMFA();

  // state
  const [step, setStep]
    = useState<"setup" | "verify">("setup");
  const [setupUrl, setSetupUrl]
    = useState<string | undefined>(undefined);

  // utilities
  const token = AuthenticationService.getAuthToken();

  useEffect(() => {
    requestSetupUrl(token).then(setSetupUrl)
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  let content: React.ReactNode;
  if (!setupUrl)
    content = (
      <div className="relative w-10 h-10">
        {/* @ts-ignore */}
        <Spinner styleType="white"/>
      </div>
    )
  else if (step === "setup")
    content = (
      <MFASetup
        url={setupUrl}
        onContinue={() => setStep("verify")}
        onExit={() => onExit()}
      />
    )
  else if (step === "verify") {
    const secret = new URL(setupUrl).searchParams.get("secret");
    const {sub: email} = jwtDecode(token) as {sub: string};
    content = (
      <MFAVerification
        email={email}
        onContinue={(totp) => {
          verify(totp, token, secret!)
            .then(() => {
              // Send success notification and exit
              push({
                type: "success",
                title: intl.formatMessage({
                  id: "mfa_setup_success",
                  defaultMessage: "MFA configurata con successo",
                }),
              })
              onSuccess();
            })
            .catch((error) => {
              const code = error.response?.status;
              if (code === 406)
                push({
                  type: "error",
                  title: intl.formatMessage({
                    id: "invalid_mfa_code",
                    defaultMessage: "Il codice inserito non è valido. Riprovare",
                  })
                });
              else
                push({type: "error", title: intl.formatMessage({id: "server_error"})});
            });
        }}
        onExit={() => setStep("setup")}
        showTitle
      />
    )
  }
  else
    throw new Error("Invalid step");

  return (
    // @ts-ignore
    <Modal opened onExit={onExit}>
      <div className="mt-8">
        {content}
      </div>
    </Modal>
  )
}