import {useEffect, useState} from "react";
import AuthenticationService from "@services/AuthenticationService";
import {api} from "@services/apiRequest";

interface AuthenticationMethodsSchema {
  accenture_eso: boolean;
  client_sso: boolean;
  client_sso_name: string | null;
  standard_login: boolean;
}

export const useLogin = () => {

  // state
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [methods, setMethods]
    = useState<AuthenticationMethodsSchema>();

  // handlers
  /**
   * Submit login form to the server and return the resulting access token
   * @param email - Email of the user
   * @param password - Password of the user
   * @returns Access token
   */
  const submit = async (email: string, password: string): Promise<any> => {
    setSubmitting(true);
    try {
      return await AuthenticationService.login(email.trim(), password)
    } 
    finally {
      setSubmitting(false);
    }
  }
  /**
   * Redirect to the login page of the selected authentication method on the remote tenant
   * @param type - Type of the authentication method (accenture or client)
   */
  const redirect = async (type: "accenture" | "client") => {
    const endpoint = type === "accenture" ? "/saml/login" : "/oauth2/login";
    const { data: redirectURL } = await api.get(endpoint, {publicRequest: true})
    window.location.href = redirectURL;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const {data} = await api.get<AuthenticationMethodsSchema>(
          "/auth/methods",
          {publicRequest: true}
        );
        setMethods(data);
      }
      finally {
        setLoading(false);
      }
    })()

  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return {methods, submit, redirect, loading, submitting};
}