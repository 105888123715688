import it_faq from "./faq/it_faq";
import it_form from "./form/it";

const it = {
  no_options_available: "Nessuna opzione disponibile",
  entity_user: "Gestione delle utenze in sistema",
  entity_role: "Gestione dei ruoli di autorizzazione in sistema",
  name_and_surname: "Nome e cognome",
  entity_audit: "Gestione dell'audit",
  language_it: "Italiano",
  language_en: "English",
  language: "Lingua",
  Economics: "Economics",
  revenue: "Dashboard Ricavi",
  gescli: "Analisi Ore",
  cloud: "Costi Cloud",
  productivity: "Produttività",
  Courses: "Corsi",
  Evaluation: "Valutazioni",
  Home: "Home",
  OKRs: "OKRs",
  Projects: "Progetti",
  Settings: "Settings",
  access_denied: "Accesso negato",
  active: "attivi",
  active_okr: "OKR attivi",
  add: "Aggiungi",
  add_area: "Aggiungi Area",
  add_client_name: "Specifica il nome del cliente",
  add_cod_customer: "Inserisci codice",
  add_dat_finish: "Seleziona la data",
  add_dat_init: "Seleziona la data",
  add_des_customer: "Inserisci nome cliente",
  add_project_description: "Inserisci una descrizione del progetto (opzionale)",
  add_project_name: "Specifica il nome del progetto",
  add_project_number: "Specifica la commessa",
  add_course: "Aggiungi Corso",
  add_link: "Add link",
  add_new_course: "Aggiungi un nuovo corso",
  add_new_items: "Aggiungi nuovi elementi",
  add_new_skill: "Aggiungi nuova Skill",
  add_project: "Aggiungi progetto",
  add_role: "Aggiungi ruolo",
  add_role_name: "Inserisci il nome del ruolo",
  add_skill: "Aggiungi Skill",
  add_topic: "Aggiungi Topic",
  add_type: "Aggiungi Type",
  add_user: "Aggiungi utente",
  all_courses: "Tutti i corsi",
  all_projects: "Tutti i progetti",
  area: "area",
  areas: "Aree",
  attendance_report: "Consuntivazione corsi",
  audit: "Audit",
  authentication_failed: "Autenticazione fallita",
  avg_course_evaluation: "Voto medio del corso",
  backend: "Backend",
  cancel: "Annulla",
  choose_person_evaluation: "Seleziona la persona che vuoi valutare",
  click_to_drill_down: "Clicca sulle aree per il drill down",
  client_name: "Nome cliente",
  cod_customer: "Codice cliente",
  code: "Codice",
  come_back: "Indietro",
  commessa: "Commessa",
  completion_progress: "Percentuale completamento",
  confirm_delete_project: "Sei sicuro di voler eliminare il progetto?",
  confirm_password: "Conferma password",
  confirm_remove_participation: "Sei sicuro di voler rimuovere la partecipazione?",
  corsi: "Corsi",
  course: "Corso",
  course_followers: "Chi ha seguito questo corso?",
  course_subscription_success: "Stai partecipando al corso!",
  course_type: "Tipo di corso",
  covered_skills: "Skills coperte",
  create_new_evaluation: "Crea una nuova sessione",
  create_new_okr: "crea nuovo okr",
  customer: "Cliente",
  customer_added: "Cliente aggiunto",
  data_end_does_not_exist: "Data fine does not exists",
  delete: "Elimina",
  delete_project: "Elimina progetto",
  delete_subscription_success: "Operazione avvenuta con successo",
  des_customer: "Nome cliente",
  disabled_user: "Utente disabilitato",
  edit: "Modifica",
  edit_okr: "Modifica OKR",
  end_date: "Data di fine",
  end_date_picker: "Scegli la data di fine",
  end_date_picker_error: "Seleziona una data di fine",
  enter_name_and_surname: "Inserisci nome e cognome",
  enter_name_or_email: "Inserisci email e nome",
  err_load_legend: "Errore durante il caricamento della legenda",
  error_loading: "Errore durante il caricamento",
  error_updating_note: "Errore di aggiornamento nota",
  error_updating_okr: "Errore durante l'aggiornamento dell'OKR",
  error_updating_okr_name: "Errore durante l'aggiornamento del Nome dell'OKR",
  error_updating_task_list: "Errore durante l'aggiornamento della lista di Task",
  estimated_deadline: "Data di fine prevista",
  eval_the_course: "Valutazione del corso",
  evaluate_your_team: "Valuta il tuo team",
  evaluate_your_team_description: "Monitora e aggiorna le skills del tuo gruppo",
  evaluation: "Valutazione",
  evaluation_success: "Grazie per la tua valutazione!",
  goto_course_site: "Vai al sito del corso",
  part: "Parte",
  historic_okr: "OKR storici",
  hours: "ore",
  hours_insert_success: "Ore aggiunte correttamente!",
  hours_report: "Rendicontazione ore",
  hours_spent: "Quante ore hai dedicato al corso?",
  id_course: "Codice corso",
  inactive: "inattivi",
  info: "Info",
  item_added: "Elemento aggiunto correttamente",
  item_deleted: "Elemento eliminato correttamente",
  item_edited: "Elemento modificato correttamente",
  legend: "Legenda",
  legend_grade_na: "Non ho informazioni per valutare",
  legend_grade_0: "Nessuna idea di cosa sia l'argomento",
  legend_grade_1: "Conoscenza basilare",
  legend_grade_2: "Necessaria supervisione",
  legend_grade_3: "Autonomia",
  legend_grade_4: "Piena padronanza",
  legend_grade_5: "Profonda conoscenza dell'argomento",
  missing_data: "Dati mancanti",
  missing_dates: "Date non impostate",
  modify: "Modifica",
  modify_staffing: "Modifica il periodo di staffing",
  my_courses: "I miei corsi",
  my_current_projects: "I miei progetti attuali",
  my_evaluations: "Le mie valutazioni (WIP)",
  my_evaluations_description: "Consulta le valutazioni ricevute dai tuoi responsabili",
  my_okr: "I miei OKRs",
  my_projects: "I miei progetti",
  my_skills: "Le mie skills",
  my_team: "Il mio team",
  need_to_select_a_role: "Devi selezionare un ruolo",
  delete_user: "Eliminare l'utenza?",
  disabled: "Disabilitato",
  user_updated_successfully: "Utenza aggiornata con successo",
  user_deleted_successfully: "Utenza eliminata con successo",
  user_created_successfully: "Utente creato con successo",
  description: "Descrizione",
  role_name: "Nome ruolo",
  deleted_role: "Ruolo eliminato",
  updated: "Aggiornati",
  delete_element: "Eliminare l'elemento?",
  role_already_used: "Ruolo già in uso!",
  role_created_successfully: "Ruolo creato con successo",
  server_error: "Errore del server",
  confirm_new_password: "Conferma nuova password",
  passwords_not_matching: "Le due password non coincidono",
  password_without_spaces: "La password non deve contenere spazi",
  password_length: "La password deve essere lunga almeno 8 caratteri",
  signup: "Registrati",
  skills: "Skills",
  skills_added: "Skill aggiunte",
  skills_deleted: "Skill eliminate",
  something_went_wrong: "Qualcosa è andato storto",
  speaker: "Speaker",
  staff: "Staff",
  start_date: "Data d'inizio",
  start_date_picker: "Scegli la data di inizio",
  start_date_picker_error: "Seleziona una data di inizio",
  submit_eval: "Salva valutazione",
  submit_hours_report: "Salva rendicontazione",
  submit_okr: "Applica",
  submit_update: "Aggiorna",
  superior: "Superiore",
  task: "Task",
  task_error: "Inserisci il nome del task",
  task_title: "Lista Task",
  taxonomy: "Tassonomia",
  teams_progress: "Progresso dei team",
  thanks_for_eval: "Grazie per la tua valutazione!",
  topic: "topic",
  topics: "Topics",
  type: "gruppo",
  types: "Gruppi",
  undo_update: "Annulla",
  update: "Aggiorna",
  update_course: "Modifica il corso",
  update_task_name_error: "Errore aggiornamento nome task",
  update_task_prog_error: "Errore aggiornamento progresso task",
  user: "Utente",
  error_while_saving: "Errore durante il salvataggio",
  send: "Invia",
  user_not_found: "Utente non trovato",
  wrong_old_password: "Vecchia password errata",
  enter_actual_password: "Inserisci password attuale",
  actual_password: "Password attuale",
  new_password: "Nuova password",
  email: "Email",
  name: "Nome",
  role: "Ruolo",
  user_type: "Tipo utente",
  my_profile: "Il mio profilo",
  mfa: "Multi Factor Authentication",
  password_updated_successfully: "Password modificata con successo",
  submit_password: "Invia password",
  enter_your_email_address:
    "Inserisci l'indirizzo email associato alla tua utenza",
  restore_password: "Recupera password",
  sign_in: "Accedi",
  client_sso: "client sso",
  accenture_sso: "accenture sso",
  forgot_password: "Recupera password",
  edit_password: "Modifica password",
  show_password: "Mostra password",
  form_error: "Controlla i campi del form",
  enter_email: "Inserisci email",
  missing_sender_mail: "Mail del mittente mancante",
  log_into_your_account: "Entra nel tuo account",
  wrong_email_or_password: "Email o password sbagliati. Tentativi rimasti: {number}",
  wrong_otp: "Errore otp",
  user_locked: "Account utente bloccato per troppi tentativi, aspetta 30 minuti e riprova",
  password_expired: "Password scaduta, resettare la password utilizzando il tasto \"Recupera password\"",
  password_expiring: "La tua password scadrà tra {days} giorni",
  password_expiring_title: "Password in scadenza!",
  unregistered_email: "Email non registrata",
  forgot_password_msg: "Mail inviata! Se non ricevi alcuna notizia da noi nei prossimi 15 minuti, controlla di aver inserito l'indirizzo e-mail corretto e controlla nella cartella spam.",
  roles: "Ruoli",
  roles_displayed_rows: "{from}-{to} di {count} ruoli",
  users: "Utenti",
  users_displayed_rows: "{from}-{to} di {count} utenti",
  components: "Componenti",
  maps: "Mappe",
  tables: "Tabelle",
  graphs: "Grafici",
  users_settings: "Gestione utenze",
  view_mode: "Vista",
  welcome: "Benvenuto",
  test: "test_it",
  frontend: "Frontend",
  file_picker_placeholder: "Seleziona uno o più file",
  success_ticket_sent: "Il ticket è stato aggiunto correttamente, prenderemo in carico la richiesta il prima possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Dimensione massima superata",
  customer_portal_loading_failed: "Errore durante il caricamento del link al portale cliente",
  week_datepicker: "Settimana ",
  week_datepicker_not_selected: "Datepicker settimanale",
  daily_datepicker_not_selected: "Datepicker giornaliero",
  thanks: "Grazie!",
  select_an_option: "Seleziona un'opzione dal menu a tendina.",
  ticket_parameters_not_found: "Parametri di invio ticket non presenti",
  troisi_parameters_not_found: "Parametri di invio mail non presenti",
  error_new_ticket_creation: "Errore imprevisto nella creazione di un nuovo ticket",
  error_ticket_hubspot_management: "É stato creato un nuovo ticket, ma qualcosa è andato storto durante le operazioni effettuate dal nostro portale",
  error_ticket_file_attachment: "É stato creato un nuovo ticket, ma non è possibile allegare il file specificato",
  session_expired: "Sessione scaduta",
  refreshing_session_wait: "Rinnovo della sessione in corso, attendere...",
  email_already_in_use: "Email già in uso",
  mfa_guide_guide1_title: "Perché è necessario?",
  mfa_guide_guide1_text: "L'autenticazione a due fattori (2FA) aggiunge un ulteriore livello di sicurezza al tuo account. Anche se qualcuno riesce a scoprire la tua password, non potrà accedere al tuo account senza il codice di verifica impostato sul tuo dispositivo.",
  mfa_guide_guide2_title: "Cosa mi serve per l'autenticazione?",
  mfa_guide_guide2_text: "È necessario scaricare un’app apposita per l’autenticazione multi-fattori sul tuo smartphone. Ecco come:",
  mfa_guide_guide2_text_item1: "Scarica dall’App store o Play store un’app di autenticazione, come Google Authenticator o Microsoft Authenticator",
  mfa_guide_guide2_text_item2: "Apri l’app e aggiungi un account. Nel caso di Microsoft Authenticator, è necessario prima selezionare “account aziendale o dell’istituto di istruzione”",
  mfa_guide_guide2_text_item3: "Per collegare la tua app al tuo account sulla piattaforma è ora sufficiente inquadrare il codice QR dall’applicazione, o inserire il codice numerico",
  mfa_guide_guide2_text_item4: "Se l’applicazione mostra il codice di accesso a 6 cifre, hai terminato l’installazione!",
  mfa_guide_guide3_title: "Non riesco ad accedere",
  mfa_guide_guide3_text: "Se il codice di verifica non funziona, contatta l'amministratore del sistema al seguente indirizzo: {address}",
  authentication_method: "Metodo di autenticazione",
  auth_method_accenture_eso: "Accenture SSO",
  auth_method_client_sso: "{client} SSO",
  auth_method_standard_login: "Email e password",
  generic_error: "Errore imprevisto",
  area_experts: "Esperti del settore",
  select_area: "Seleziona un'area per vedere gli esperti del settore",
  training_hours: "Ore di formazione",
  available_hours: "Ore disponibili",
  no_evaluations_message: "Non sono presenti valutazioni. Vai alle",
  no_okr: "Nessun OKR visibile",
  no_okr_message: "Non hai ancora definito i tuoi obiettivi. Vai alla sezione",
  no_projects_message: "Non hai progetti attivi",
  not_defined: "Non definito",
  note_does_not_exist: "La nota non esiste",
  notes: "Note",
  of: "di",
  okr_collapse: "Comprimi",
  okr_creation_success: "OKR creato con successo!",
  okr_elimination_confirmation_cancel: "Annulla",
  okr_elimination_confirmation_delete: "Elimina",
  okr_elimination_confirmation_message: "Non potrai annullare l'eliminazione.",
  okr_elimination_confirmation_title: "Sei sicuro di voler eliminare l'OKR?",
  okr_expand: "Espandi",
  okr_error_task_progress: "Il progresso delle task deve essere compreso tra 0 e 100.",
  okr_hint_name: "Scrivi qui il nome dell'OKR...",
  okr_hint_note: "Scrivi qui le note dell'OKR...",
  okr_none: "Nessun OKR",
  okr_status_active: "Attivo",
  okr_status_expired: "Scaduto",
  okr_status_expiring: "In scadenza",
  okr_task_add: "Aggiungi task...",
  okr_title: "Nome",
  okr_title_input: "Nome OKR",
  okr_title_input_error: "Inserisci un titolo per l'okr",
  okr_visibility_title: "Visibilità",
  okr_visibility_message: "Chi vedrà questo OKR?",
  okr_visibility_private: "Privato",
  okr_visibility_public: "Pubblico",
  okr_visibility_team: "Team",
  okrs: "OKRs",
  organization_chart: "Organigramma aziendale",
  partecipate: "Partecipa",
  password_policy: "Inserire una password lunga almeno 8 caratteri, senza spazi",
  positive_hours_error: "Number of hours must be greater than 0",
  positive_integer_error: "Error: insert a positive integer",
  project_added: "Progetto aggiunto",
  project_deleted: "Progetto eliminato",
  project_description: "Descrizione del progetto",
  project_name: "Nome del progetto",
  project_number: "Commessa",
  project_status: "Stato progetto",
  project_updated: "Progetto aggiornato",
  projects: "Progetti",
  recap_okr: "Recap Okr",
  referent: "Referente",
  remove_link: "Rimuovi",
  remove_participation: "Cancella iscrizione",
  remove_skill: "Elimina skill",
  select_a_project: "Seleziona un progetto",
  select_evaluation_date: "Selezionare la sessione di valutazione",
  self_evaluation: "Autovalutazione",
  self_evaluation_description: "Gestisci le tue valutazioni personali",
  session_of: "Sessione del",
  settings_tooltip: "Aggiungi una nuova skill e, se non sono presenti, anche i corrispondenti topic, type e area.",
  show_past_projects: "Mostra progetti passati",
  you_will_lose_data: "Non sarà possibile recuperare i dati",

  // Monitoring
  monitoring: "Monitoring",
  customers: "Cliente",
  detail: "Dettaglio",
  production: "Produzione",
  develop: "Sviluppo",
  last_run_status: "Stato:",
  last_run_date: "Data:",
  users_num: "# utenti",
  last_week_access: "# accessi last week",
  production_app_list: "App in produzione",
  app_version: "Versione App",
  template_app_version: "Versione aTemplateApp",
  acli_version: "Versione acli",
  cdk_version: "Versione CDK",
  waf_enabled: "WAF abilitato",
  audit_detail: "Dettaglio Audit",
  registered_users: "Utenti registrati",
  login_number: "Conteggio Login",
  last_day_accesses: "Accessi ultimo giorno",
  last_week_accesses: "Accessi ultima settimana",
  last_month_accesses: "Accessi ultimo mese",
  host_in_downtime: "Host ACP in downtime",
  monitoring_not_active: "Monitoring non attivo per ambiente di produzione",
  host_without_summary: "Hosts in attesa di monitoraggio",

  ...it_faq,
  ...it_form,
};

export default it;
