import {useState} from "react";
import {api} from "@services/apiRequest";

type VerificationReturnType = {access_token: any, refresh_token: any, user_data: any};
export const useMFA = () => {

  const [loading, setLoading] = useState(false);

  // handlers
  const requestSetupUrl = async (token: string) => {
    const {data} = await api.get<string>(
      "/mfa/setup-url",
      {headers: {Authorization: `Bearer ${token}`}},
    );
    return data;
  }
  const verify = async (
    totp: string,
    accessToken: string,
    secret?: string,
  ): Promise<VerificationReturnType> => {
    setLoading(true);
    try {
      const payload = !!secret ? {totp, secret} : totp;
      const {data} = await api.post<VerificationReturnType>(
        !!secret ? "/mfa/setup" : "/mfa/verify",
        payload,
        {
          headers: {Authorization: `Bearer ${accessToken}`}
        });
      return data;
    } finally {
      setLoading(false);
    }
  }

  return {loading, requestSetupUrl, verify};
}