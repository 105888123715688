import {TOTPInputProps} from "./types";
import React, {useCallback, useRef} from "react";

export const TOTPInput = ({
  otp,
  setOtp,
}: TOTPInputProps) => {

  // refs
  const inputRefs = useRef<HTMLInputElement[]>([]);

  // handlers
  const handleChange = useCallback((index, value) => {
    if (/^\d?$/.test(value)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOtp(newOTP);

      // Move focus to next input
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  }, [otp, setOtp]);
  const handleKeyDown = useCallback((index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  }, [otp]);
  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    if (/^\d{4}$/.test(pastedText)) {
      const digits = pastedText.split('');
      setOtp(digits);
    }
  }, [setOtp]);

  return (
    <div className="flex items-center justify-center gap-3">
      {otp.map((digit, index) => (
        <input
          key={index}
          ref={(el: HTMLInputElement) => inputRefs.current[index] = el}
          type="text"
          value={digit}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
          maxLength={1}
          className={
            "w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent "
            + "hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-am-500 "
            + "focus:ring-2 focus:ring-am-500 focus:ring-opacity-50"
          }
        />
      ))}
    </div>
  )
};