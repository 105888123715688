import {useState} from "react";
import {api} from "@services/apiRequest";

export const usePasswordRecovery = () => {

  // state
  const [loading, setLoading] = useState(false);

  // handlers
  const submit = async (email: string) => {
    setLoading(true);
    try {
      await api.post(
        "/users/password/forgot",
        {email},
        {publicRequest: true}
      );
    }
    finally {
      setLoading(false);
    }
  }

  return {submit, loading};
}